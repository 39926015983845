<script lang="ts">
	// Modified code for src/routes/+page.svelte
	import { onMount } from 'svelte';
	import { goto } from '$app/navigation';
	import PageWrapper from '$lib/components/molecules/PageWrapper.svelte';
	import SplashScreenBox from '$lib/components/organisms/splash-screen/SplashScreenBox.svelte';
	import SplashScreenButton from '$lib/components/organisms/splash-screen/SplashScreenButton.svelte';
	import { handleNftCheck, callApi } from '$lib/utils/api';
	import LoadingScreen from '$lib/components/atoms/LoadingScreen.svelte';
	import { api } from '$lib/stores/generalStore';
	import { nomo } from 'nomo-webon-kit/dist/nomo_api';
	import { languages } from '$lib/stores/languages';
	import { countries } from '$lib/stores/countriesStore';
	import { browser } from '$app/environment';
	import { runsAsWebOn } from 'nomo-webon-kit';
	import { waitLocale } from 'svelte-i18n';

	let loading = true;

	onMount(async () => {
		await waitLocale()
		if (browser && !runsAsWebOn()) {
			// Do not display anything in this case
			goto('/server-unavailable');
		}

		try {
			const hasNft = await handleNftCheck();
			if (!hasNft) {
				const messengerRes = await nomo.getMessengerAddress();

				await callApi('GET', '/generate_zenpass', undefined, {
					'messenger-code': messengerRes.messengerAddress,
				});
			}

			// Check if languages are already loaded in store
			const languagesRes = await callApi('GET', '/get_languages');
			console.log("Languages response from callApi:", languagesRes); // Add logging

			// If callApi returns undefined because API sent array directly
			if (!languagesRes) {
				// Optional: Try fetching again assuming raw response if needed,
				// but better to fix API or callApi if this is the case.
				console.warn('/get_languages did not return data, potentially missing "data" wrapper in API response.');
			} else if (Array.isArray(languagesRes) && languagesRes.length > 0) {
				// This handles the case where API returns { "data": [...] } and callApi extracts [...]
				languages.set(languagesRes);
			} else {
				// This handles the case where API returns { "data": [] }
				console.warn('/get_languages returned an empty array.');
			}

			try {
				const countriesResponse = await callApi('GET', '/get_countries');
				if (Array.isArray(countriesResponse)) { // Check if it's an array
					countries.set(countriesResponse); // Use the new store's set method
					console.log("Countries loaded and stored.");
				} else {
					console.warn('/get_countries did not return an array as expected.');
				}
			} catch (countryError) {
				console.error("Error fetching countries:", countryError);
			}

			// Fetch kycServices (assuming there's an endpoint for this)
			const kycServicesResponse = await callApi('GET', '/get_kyc_services');
			if (kycServicesResponse && kycServicesResponse.data) {
				api.update(store => ({ ...store, kycServices: kycServicesResponse.data }));
			}

			loading = false;

			if(hasNft) {
				goto('/overview');
			}
		} catch (error) {
			console.error('Error initializing data:', error);
			loading = false;
		}
	});

	function handleStart() {
		goto('/overview');
	}
</script>

{#if loading}
	<LoadingScreen />
{:else}
	<PageWrapper customBackFunction="" headerTitle="" showHeaderBox={false}>
		<SplashScreenBox slot="information-content" />
		<SplashScreenButton slot="button-content" on:click={handleStart} />
	</PageWrapper>
{/if}
